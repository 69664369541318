@import '~theme/variables';

@layer features {
  .footerLink {
    color: $color-neutral-06;
    font-weight: 400;
    transition: color $transition-01;
    display: inline-flex;
    gap: $space-xs;
    align-items: center;

    &:hover {
      color: $color-primary;
    }
  }

  .extraFooterItem {
    margin-top: $space-s;
  }

  .extraFooterLink {
    text-decoration: underline;

    &:hover {
      text-decoration: none;

      font-weight: 400;
      transition: color $transition-01;

      color: $color-primary;
    }

    .footerItem {
      margin-bottom: $space-s;
      margin-right: $space-m;

      @include breakpoint(sm) {
        margin-bottom: $space-xs;
      }
    }

    .extraFooterItem {
      margin-top: $space-s;
    }

    .extraFooterLink {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
        color: $color-neutral-03;
      }
    }

    .small {
      font-size: $small-font-size;
    }
  }
}
