@import '~theme/variables';

.column {
  flex: 1;
  margin-bottom: 0;
  border-bottom: 1px solid #343331;

  @include breakpoint(sm) {
    border-bottom: none;
    margin-bottom: $space-s;
  }
  @include breakpoint(md) {
    margin-bottom: 0;
  }
}

.header {
  color: $color-neutral-06;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $space-xs 0;

  @include breakpoint(sm) {
    padding-top: 0;
    border-bottom: 1px solid #343331;
  }
}

.title {
  font-size: 3.2rem;
  font-family: $font-family-display;
  color: $color-neutral-06;
}

.list {
  margin-top: $space-xs;
  margin-bottom: $space-s;

  @include breakpoint(sm) {
    margin-top: $space-m;
    margin-bottom: 0;
  }

  li {
    padding-bottom: $space-xs;

    &:last-of-type {
      padding: 0;
    }
  }
}
