@import '~theme/variables';

.clubSection {
  margin-top: 4rem;
}

.row {
  row-gap: 4rem;

  .col {
    padding: 0 2rem;
  }
}

.card {
  border-radius: $border-radius-04;
}
