@import '~theme/variables';

.container {
  padding: 2.4rem;
  box-shadow: $box-shadow-01;
  border-radius: $border-radius-03;
  border: 0.1rem solid $color-neutral-05;
  background: $color-neutral-06;

  > :nth-child(n + 4) {
    margin-top: 1.6rem;
  }
}

.item {
  display: flex;
  justify-content: space-between;
  & + & {
    margin-top: 0.8rem;
  }
}

.innerContainer {
  background-color: $color-neutral-05;
  padding: 0.8rem;
  border-radius: $border-radius-02;
}

.openingHoursList {
  margin-top: $space-s;
}

.openingHoursExceptionsList {
  margin-top: 0.4rem;
}

.capitalize:first-letter {
  text-transform: capitalize;
}
