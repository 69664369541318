@import '~theme/variables';
@import '../index.module.scss';

.contentMobile {
  position: relative;
  z-index: $z-index-dialog;
}

.contentOpen {
  background-color: $color-neutral-06;
  svg {
    fill: $color-neutral-01;
  }
}

.logoLink {
  display: flex;
}

.listItem {
  padding: $space-s $space-m;
  transition: background-color $transition-01;
  border-radius: $border-radius-02;
  color: $color-neutral-01;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.8rem;

  &.primary {
    a {
      color: $color-neutral-01;
      &.active::after {
        background-color: $color-neutral-01;
      }
    }
  }

  &.secondary {
    a {
      color: $color-neutral-02;
      &.active::after {
        background-color: $color-neutral-02;
      }
    }
  }

  a {
    padding: $space-s $space-s;
    border-radius: $border-radius-02;
    position: relative;
    &.active::after {
      content: '';
      transition: background-color $transition-01;
      width: calc(100% - 2.6rem);
      height: 0.1rem;
      position: absolute;
      left: 1.6rem;
      bottom: 0.8rem;
    }
  }

  &.firstItem {
    margin-top: $space-s;
  }
}

.join.black {
  a {
    background: $color-neutral-01;
    color: $color-neutral-06;
    border: 0.2rem solid $color-neutral-01;
  }

  &.isOpen {
    a {
      border-color: $color-primary;
      background: $color-primary;
      color: $color-neutral-01;
    }
  }
}

.rightNavContainer {
  display: flex;
  align-items: center;
  gap: $space-s;
}

.navigationItem {
  list-style: none;
  display: flex;
}

.toggle {
  width: 2.4rem;
  svg {
    fill: var(--text-color);
    font-size: 2.4rem;
  }

  &.isOpen {
    svg {
      font-size: 1.6rem;
    }
  }
}

.menuListContent {
  z-index: $z-index-background;
  position: fixed;
  top: 0;
  right: -100vw;
  width: 100%;
  height: 100vh;
  background-color: $color-neutral-06;
  transition: transform $transition-01;

  .menuList {
    border-top: 1px solid $color-neutral-04;
    overflow-y: auto;
    max-height: 100vh;
    padding-bottom: 30rem;
    padding-top: $space-xs;
  }

  &.open {
    transform: translateX(-100vw);
  }
}

.openMenuBar {
  padding-top: 6.4rem;
}
