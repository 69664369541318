@import '~theme/variables';

.container {
  display: flex;
  flex-direction: column;
  padding-top: 2rem;
  padding-bottom: 2rem;

  @include breakpoint(sm) {
    gap: $space-m;
    padding-top: 4rem;
    padding-bottom: 4rem;
    justify-content: center;
    margin-top: $space-xxs;
    flex-direction: row;
  }
}
