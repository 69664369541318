@import '~theme/variables';

.container {
  position: relative;
  width: 100%;
  aspect-ratio: 16/9;
  background: $color-neutral-05;
}

.imageCarousel {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;

  &:hover,
  &:focus-within {
    & > button {
      opacity: 0.8;
    }
  }

  // Arrow buttons
  & > button {
    background-color: $color-neutral-06;
    width: 3.2rem;
    height: 3.2rem;
    opacity: 0.8;

    @include breakpoint(md) {
      opacity: 0;

      &:hover {
        background-color: $color-neutral-06;
        opacity: 1;
      }
    }

    &:hover {
      background-color: $color-neutral-06;
    }

    &:active {
      opacity: 1;
    }

    svg {
      fill: $color-neutral-01 !important;
    }
  }
}

.slide {
  position: relative;
  aspect-ratio: 16/9;
}

.image {
  object-fit: cover;
}

.sliderDots {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  gap: 8px;
  z-index: 10;
  background-color: $color-neutral-06;
}
