@import "~theme/variables";

.attribute {
  display: flex;
  align-items: center;
  padding: $space-xxs $space-xs;
  border: 0.1rem solid;
  border-radius: $space-xs;
  background: $color-neutral-06;

  svg {
    margin-right: $space-xxs;
    font-size: $h3-font-size;
  }
}

.attributeList {
  margin-top: $space-m;
  display: flex;
  flex-wrap: wrap;
  gap: $space-xs;
  align-items: center;
}

.link {
  padding: $space-xxs 0;
}

.disableUnderline {
  display: inline-block;
  text-decoration: none;
  margin-right: .3rem;
}