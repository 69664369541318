$layout-max-container-width: 1200px;

$layout-grid-columns: 12;

$layout-breakpoints: (
  xs: 0,
  sm: 768px,
  md: 1024px,
  lg: 1440px,
);

$layout-grid-col-gap: (
  xs: 16px,
  sm: 16px,
  md: 24px,
  lg: 24px,
);

$layout-grid-row-gap: (
  xs: 16px,
  sm: 16px,
  md: 24px,
  lg: 24px,
);

$layout-grid-offset: (
  xs: 16px,
  sm: 40px,
  md: 56px,
  lg: 0,
);

@mixin breakpoint($width, $type: min) {
  @if map_has_key($layout-breakpoints, $width) {
    $width: map_get($layout-breakpoints, $width);

    @if $type == max {
      $width: $width - 1;
    }

    @media only screen and (#{$type}-width: $width) {
      @content;
    }
  }
}

:export {
  xs: map_get($layout-breakpoints, 'xs');
  sm: map_get($layout-breakpoints, 'sm');
  md: map_get($layout-breakpoints, 'md');
  lg: map_get($layout-breakpoints, 'lg');
}
