@import "~theme/variables";

.section {
  background-color: $color-neutral-05;
}

.week-navigator {
  justify-content: center;
  align-items: center;
  padding: $space-l 0;
  display: flex;
  flex-direction: row;
}

.navigation-button {
  fill: $color-neutral-04;
  margin: 0 $space-s;

  &.active {
    fill: $color-primary;
    cursor: pointer;
  }
}