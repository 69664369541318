@import '~theme/variables';

.list {
  display: flex-inline;
  margin: 0 auto;
  padding: 0.2rem;
  gap: 0.2rem;
  list-style: none;
  border-radius: 9999px;
  border: 2px solid $color-neutral-01;
  background: $color-neutral-06;
  width: fit-content;
}

.trigger {
  padding: 1.2rem 2.4rem;
  font-size: $body-font-size;
  line-height: 1;
  font-weight: 600;
  color: $color-neutral-02;
  border-radius: 9999px;

  &:hover {
    color: $color-neutral-01;
    background: $color-neutral-04;
  }

  &[aria-selected='true'],
  &[aria-selected='true']:hover {
    color: $color-neutral-06;
    background: $color-neutral-01;
  }
}

.dropdownList {
  width: 100%;
  padding: 0rem;
}

.dropdownTrigger {
  display: flex;
  width: 100%;
  padding: 1.4rem 2rem;
  justify-content: space-between;
  align-items: center;
  color: $color-neutral-01;

  & svg {
    width: 1.6rem;
    height: 1.6rem;
    transition: transform 160ms 0ms ease-in-out;
  }

  &[aria-expanded='true'] {
    & svg {
      transform: rotate(180deg);
    }
  }
}

.dropdownTriggerItem {
  display: none;

  &[aria-selected='true'] {
    display: block;
  }
}

.dropdownContent {
  position: relative;
  margin: $space-xs auto 0;
  padding: 0.2rem;
  gap: 0.2rem;
  list-style: none;
  border-radius: $border-radius-04;
  border: 2px solid $color-neutral-01;
  background: $color-neutral-06;
  width: fit-content;
  overflow: hidden;

  &[data-state='open'] {
    animation-name: slideDown;
    animation-duration: 0.6s;
    animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  }

  & > div {
    width: var(--radix-dropdown-menu-trigger-width);
    border-radius: 2rem; // Parent border-radius - parent padding
    overflow: hidden;
  }
}

.dropdownItem {
  display: flex;
  width: 100%;
  border-radius: 0;
  text-align: left;
  align-items: center;
  gap: $space-s;
  padding: 1.6rem 2rem;
  font-weight: 600;

  & svg {
    visibility: hidden;
    fill: $color-neutral-06;
  }

  &:hover,
  &:focus {
    color: $color-neutral-01;
    background: $color-neutral-04;
    outline: none;
  }

  &[aria-selected='true'],
  &[aria-selected='true']:hover {
    color: $color-neutral-06;
    background: $color-neutral-01;

    & svg {
      visibility: visible;
    }
  }
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
