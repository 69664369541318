@import '~theme/variables';

.list {
  display: flex;
  gap: $space-xs;
  align-items: center;

  @include breakpoint(sm) {
    gap: $space-s;
  }

  @include breakpoint(md) {
    gap: $space-l;
  }
}
