@import '~theme/variables';

.container {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 6.8rem;
  height: 100%;
  transition: transform $transition-01;
  transform: translateY(calc(100% - 23rem));
  z-index: 10;
}

.expanded {
  transform: translateY(0);
}
