@import '~theme/variables';

.container {
  display: relative;

  span {
    position: absolute;
    display: block;

    text-align: center;
    top: $space-xs;
    left: 0;
    right: 0;
    font-weight: 700;
    font-size: 1.8rem;
  }
}
