$space-xxxl: 12.8rem;
$space-xxl: 6.4rem;
$space-xl: 4.8rem;
$space-l: 3.2rem;
$space-m: 2.4rem;
$space-s: 1.6rem;
$space-xs: 0.8rem;
$space-xxs: 0.4rem;

$space-vertical: (
  xs: $space-l,
  sm: $space-xl,
  md: $space-xxl,
  lg: $space-xxxl
)
