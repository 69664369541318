@import '~theme/variables';

.link {
  display: inline-flex;
  align-items: center;
  gap: $space-xs;
  transition: color 0.2s ease-in-out;
  font-weight: 500;

  &.disabled {
    pointer-events: none;
    color: $color-neutral-03;
  }

  &.active {
    pointer-events: none;
    color: $color-neutral-01;
  }

  &.checked {
    color: $color-neutral-01;
    &:hover {
      color: $color-primary;
    }
  }

  @include breakpoint(md, max) {
    .label {
      display: none;
    }

    &.active .label {
      display: inline-flex;
    }
  }
}

.indicator {
  color: $color-neutral-06;
  background-color: $color-neutral-03;
  border-radius: 50%;
  width: 2.4rem;
  height: 2.4rem;
  display: flex;
  justify-content: center;

  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1.9;

  &.active {
    background-color: $color-primary;
  }

  &.checked {
    background-color: $color-neutral-01;
    align-items: center;
  }
}
