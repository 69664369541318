@import '~theme/variables';

.card {
  position: absolute;
  right: 7.2rem;
  top: 11.2rem;
  width: 32.8rem;
  @include breakpoint(sm, max) {
    right: 2.2rem;
    top: 7.2rem;
  }
}

.top {
  display: flex;
  justify-content: end;
  align-items: flex-end;
  background-color: transparent;
}

.content {
  background-color: $color-neutral-06;
  padding: $space-m;
  display: flex;
  position: relative;
  flex-direction: column;
  border-radius: $border-radius-03;
  overflow: hidden;
  box-shadow: $box-shadow-01;
}

.locationIcon {
  width: 2.4rem;
  height: 2.4rem;
  margin-bottom: $space-xs;
}

.title {
  margin-bottom: $space-xs;
  font-weight: 700;
  line-height: 25.6px;
}

.link {
  text-decoration: underline;
  line-height: 25.6px;
}

.closeButton {
  width: 4rem;
  height: 5rem;
  cursor: pointer;
  background-color: $color-neutral-06;
  border-radius: 5rem;
  display: flex;
  align-self: flex-end;
  justify-content: center;
  align-items: center;
  margin-bottom: $space-xs;

  svg {
    font-size: 1.6rem;
  }

  @include breakpoint(sm, max) {
    height: 4rem;
  }
}
