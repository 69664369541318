@import "~theme/variables";

.list {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1.2rem;
  width: 100%;
}

.item + .item {
  margin-left: 1.6rem;
}

.indicator {
  display: inline;
  position: relative;
  margin-left: 0.4rem;
  top: 0.3rem;
}

.label {
  position: relative;
  display: inline-block;
  margin-left: 0.8rem;
  top: 50%;
}

.validationLabel{
  margin-top: 0.8rem;
}
