@import "~theme/variables";

.section {
  background-color: $color-neutral-05;
  min-height: calc(100vh - 6rem);
}

.day-list {
  margin-top: 2.4rem;
}
