@import '~theme/variables';

.toggleButton {
  height: 5.2rem;
  width: 5.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  box-shadow: $box-shadow-01;
  border-radius: $border-radius-03;

  & svg {
    transition: transform $transition-01;
  }
  @include breakpoint(sm) {
    display: none;
  }
}

.expanded svg {
  transform: rotate(180deg);
}
