@import '~theme/variables';

.searchBar {
  display: flex;
  border-radius: $border-radius-04;
  background: $color-neutral-05;
  margin-bottom: 2.4rem;
  padding: 1.2rem;
  align-items: center;
  flex-direction: column;
  justify-content: stretch;
  gap: 1.6rem;

  @include breakpoint('md') {
    flex-direction: row;
    gap: 4.8rem;
    padding: 1.6rem;
  }
}

.inputWrapper {
  flex: 1;
}

.input {
  background: $color-neutral-06;
}

.searchButton {
  flex-shrink: 0;
  padding: 1.5rem;
  & span {
    font-size: 2.4rem;
  }
}

.form {
  flex: 1;
  width: 100%;
  display: flex;
  gap: 0.8rem;
}

.locationButton {
  text-decoration: none;
  padding: 0.8rem 1.6rem;

  & svg {
    fill: $color-primary;
  }
}
