@import '~theme/variables';

.button {
  background-color: $color-neutral-01;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  width: 4.8rem;
  height: 4.8rem;
  transition: all 200ms ease-in-out;

  &:hover {
    background-color: $color-primary;
    .icon {
      fill: $color-neutral-01 !important;
    }
  }

  &[disabled] {
    opacity: 0.5;

    .icon {
      fill: $color-neutral-03;
    }
  }

  &.centered {
    position: absolute;
    top: calc(50% - 1.6rem);
    right: $space-s;
    z-index: $z-index-above;
  }
  &.centered.prev {
    z-index: $z-index-above;
    position: absolute;
    top: calc(50% - 1.6rem);
    left: $space-s;
  }
}

.button.absoluteTop {
  position: absolute;
  bottom: calc(100% + 1.8rem);

  @each $size, $value in $layout-grid-offset {
    @include breakpoint($size) {
      right: #{$value};
    }
  }
}

.button.prev:not(.absoluteTop),
.button.prev:not(.absoluteBottom) {
  margin-right: $space-s;
}

.button.absoluteTop.prev,
.button.absoluteBottom.prev {
  transform: translateX(-5.6rem);
}

.button.absoluteBottom {
  position: absolute;
  bottom: 0;
  z-index: $z-index-above;

  @each $size, $value in $layout-grid-offset {
    @include breakpoint($size) {
      right: #{$value};
    }
  }
}

.button.alternative {
  border-radius: 50%;
  width: 4rem;
  height: 4rem;
  position: absolute;
  top: 50%;
  right: 1.6rem;
  bottom: unset;
  transform: translateY(-50%);
  background-color: $color-neutral-06;
  z-index: $z-index-above;
  transition: $transition_01;

  @include breakpoint(md) {
    width: 4.8rem;
    height: 4.8rem;
    right: 4.2rem;
  }
  .icon {
    fill: black;
  }
  &:hover {
    background-color: $color-primary;
  }
}

.icon {
  fill: $color-primary;
}

.button.alternative.prev {
  left: 1.6rem;
  right: auto;
  transform: translateY(-50%);

  @include breakpoint(md) {
    width: 4.8rem;
    height: 4.8rem;
    left: 4.2rem;
  }
}

.hidden {
  display: none;
}
