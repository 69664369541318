@import '~theme/variables';

.gallery {
  padding-top: 6.5rem;

  &.mobile {
    overflow-y: auto;
    padding-left: $space-s;
    padding-right: $space-s;
  }

  &.desktop {
    cursor: grab;
    width: 100%;
    padding-left: $space-m;
    padding-right: $space-m;
    padding-bottom: 5.4rem;
    min-height: 60rem;
    height: calc(100vh - 15rem);
    margin: auto 0;

    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    gap: 1.6rem;

    &::-webkit-scrollbar {
      height: 0.8rem;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #fff;
      border-radius: 10rem;
    }
  }
}

.mobileItem {
  position: relative;
  aspect-ratio: 4/3;
  margin-bottom: $space-s;
  border-radius: $border-radius-03;
  overflow: hidden;

  > picture > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.galleryColumn {
  display: flex;
  color: white;
  gap: $space-s;
  position: relative;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: $border-radius-03;
  overflow: hidden;

  &:nth-child(odd) {
    min-width: 67rem;
  }

  &:nth-child(even) {
    flex-direction: column-reverse;
    min-width: 33rem;
  }
}

.button {
  position: fixed;
  z-index: $z-index-dialog-content;

  &.left {
    top: 50%;
    left: 4.2rem;
  }

  &.right {
    top: 50%;
    right: 4.2rem;
  }
}

.nonInteractive {
  pointer-events: none;
  object-fit: cover;
}

.hide {
  display: none;
}

.columnContainer {
  position: relative;
  flex: 1;
  border-radius: $border-radius-03;
  overflow: hidden;
}
