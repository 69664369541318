@import '~theme/variables';

.bgSoftPeach {
  --section-bg-color: #{$color-soft-peach};
  --hover-bg-color: #{$color-neutral-06};
}

.bgSoftSand {
  --section-bg-color: #{$color-soft-sand};
  --hover-bg-color: #{$color-neutral-06};
}

.bgYellow {
  --section-bg-color: #{$color-primary};
  --hover-bg-color: #{$color-neutral-06};
}

.bgWhite {
  --section-bg-color: #{$color-neutral-06};
  --hover-bg-color: #{$color-n300};
}

.bgTransparent {
  --section-bg-color: transparent;
  --hover-bg-color: #{$color-neutral-06};
}

.textColorWhite {
  --text-color: #{$color-neutral-06};
  --active-text-color: #{$color-primary};
}

.textColorBlack {
  --text-color: #{$color-neutral-01};
  --active-text-color: #{$color-neutral-06};
}

.rootContainer {
  position: sticky;
  height: max-content;
  width: 100%;
  top: 0;
  z-index: $z-index-header;
  background-color: var(--section-bg-color);
  padding: 0;
  transition: background-color $transition-01;

  &.sticky {
    --section-bg-color: #{$color-neutral-06};
    --hover-bg-color: #{$color-n300};
    --text-color: #{$color-neutral-01};
    --active-text-color: #{$color-neutral-06};

    box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.1);
  }

  &.bgTransparent {
    position: fixed;
  }
}

.topBannerWrapper {
  overflow: hidden;
}

.content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: $space-xs $space-s;

  @include breakpoint(lg) {
    width: $layout-max-container-width;
  }

  @include breakpoint(md) {
    margin: 0 auto;
    max-width: $layout-max-container-width;
  }

  &.mobile {
    @include breakpoint(md) {
      display: none;
    }
  }

  &.desktop {
    @include breakpoint(md, max) {
      display: none;
    }
  }
}
