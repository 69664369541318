@import '~theme/variables';

.container {
  display: flex;
  justify-content: center;
  position: relative;
  height: 50rem;
  width: 100%;

  &.expanded {
    .card {
      &:nth-child(1) {
        transform: translate3d(-48rem, 0, 0) rotate(-6.6deg);
      }

      &:nth-child(2) {
        transform: translate3d(-16rem, 9rem, 0) rotate(-2.8deg);
      }

      &:nth-child(3) {
        transform: translate3d(16rem, 0rem, 0) rotate(2deg);
      }

      &:nth-child(4) {
        transform: translate3d(48rem, 8rem, 0) rotate(4deg);
      }
    }
  }
}

.card {
  width: 40rem;
  transition: transform 0.8s
    linear(
      0,
      0.007 1.3%,
      0.027 2.6%,
      0.108 5.6%,
      0.212 8.4%,
      0.567 17.1%,
      0.674 20.1%,
      0.759 22.9%,
      0.831 25.7%,
      0.889 28.5%,
      0.938 31.5%,
      0.975 34.6%,
      1.017 40.6%,
      1.032 48%,
      1.004 76.9%,
      1
    );
  transition-delay: 0.4s;

  &:nth-child(1) {
    transform: rotate(-1.6deg);
    z-index: 3;
  }

  &:nth-child(2) {
    transition-duration: 0.6s;
    transform: rotate(2.8deg);
    z-index: 2;
  }

  &:nth-child(3) {
    transition-duration: 0.6s;
    transform: rotate(-1.4deg);
  }

  &:nth-child(4) {
    transform: rotate(-1deg);
    z-index: 1;
  }
}
