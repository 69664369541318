@import '~theme/variables';

.legalMenuContainer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  flex: 1;
}

.list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  gap: $space-xs $space-m;
}

.link {
  color: $color-neutral-03;

  &:hover {
    color: $color-neutral-06;
  }

  @include breakpoint(sm) {
    &:last-of-type {
      margin-right: $space-m;
    }
  }
}
