@import '~theme/variables';

.map {
  height: 88.2rem;
  border-radius: $border-radius-04;
  margin-bottom: $space-m;

  @include breakpoint(sm, max) {
    height: 80vh;
    border-radius: 0;
    margin-bottom: 0;
  }

  :global(.gm-style-iw) {
    padding: 0;
    border-radius: $border-radius-04;
    top: -5rem;
    background: transparent;
    box-shadow: none;
  }
  :global(.gm-style-iw-d) {
    padding: 0;
    margin: 0;
    overflow: unset !important; // !important is needed to override the default styles
    background-color: $color-neutral-06;
    border-radius: $border-radius-04;
    box-shadow: $box-shadow-01;
  }
  :global(.gm-style-iw-chr) {
    position: relative;
    height: calc(4.8rem + 0.8rem);
    button {
      // !important is needed to override the default styles
      width: 4rem !important;
      height: 4rem !important;
      position: absolute;
      top: 0.8rem;
      right: 0;
      z-index: $z-index-above;
      opacity: 1;
      border-radius: 40rem;
      background: $color-neutral-06 !important;
      display: flex !important;
      justify-content: center;
      align-items: center;

      span {
        // !important is needed to override the default styles
        width: 2rem !important;
        height: 2rem !important;
        margin: 0 !important;
      }
    }
  }

  :global(.gm-style-iw-tc) {
    &::after {
      display: none;
    }
  }
}

.clubSection {
  margin-top: 4rem;
}

.col {
  position: relative;
}

.card {
  width: 33.3rem;
}
