@import "~theme/variables";

.appButtonsContainer {
  display: flex;
  flex-direction: column;

  h5 {
    margin-bottom: $space-xs;
  }
}

.buttons {
  display: flex;
}

.marginRightM {
  margin-right: $space-m;
}

.appStoreImage{
  width: 12rem;
  height: 4rem;
}

.playStoreImage{
  width: 13.5rem;
  height: 4rem;
}