@import '~theme/variables';

.sectionButton {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row-reverse;
  gap: $space-xs;
  span {
    text-align: center;
  }
  &.textLink {
    color: $color-neutral-01;
    text-decoration: none;
    justify-content: left;
    transition: text-decoration-color $transition-01;
    text-decoration: underline 0.07em transparent;
    text-underline-offset: $space-xs;

    svg {
      fill: $color-primary;
      transition: transform $transition-01;
    }

    &:hover {
      text-decoration-color: $color-neutral-01;
      color: $color-neutral-01;

      svg {
        transform: translateX($space-xs);
      }
    }
  }
}
