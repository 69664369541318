.marker {
  background-color: rgba($color: #000000, $alpha: 0.5);
  position: relative;

  svg {
    position: absolute;
    top: -32px;
    left: -32px;

    width: 64px;
    height: 64px;
  }
}
