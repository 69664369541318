@import "~theme/variables";

.container {
  position: relative;
  border-radius: $border-radius-03;
  overflow: hidden;
  aspect-ratio: 4/3;

  &:not(.isPanel) {
    @include breakpoint(sm) {
      aspect-ratio: unset;
    }
  }
}
