@import "~theme/variables";

.canvas {
  z-index: $z-index-dialog;
  opacity: 1;
  position: fixed;
  pointer-events: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
