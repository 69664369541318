@import '~theme/variables';

.label {
  display: inline-block;
  margin-bottom: 0.8rem;
}

.icon {
  fill: $color-error;
  vertical-align: bottom;
  width: 2rem;
  height: 2rem;
}

.description {
  display: block;
  margin-top: 0.8rem;
  position: absolute;
}

.container + .container {
  margin-top: 1.6rem;
}

.indicator {
  position: relative;
  margin-left: 0.4rem;
  top: 0.3rem;
}

.success {
  color: $color-success;
}
