@import '~theme/variables';

.container {
  display: inline-flex;
  cursor: pointer;
}

.input {
  visibility: hidden;
  position: absolute;
  width: 0;
  height: 0;
  opacity: 0;
}

.checkbox {
  position: relative;
  display: block;
  flex-shrink: 0;
  border-radius: $border-radius-01;
  width: 2.4rem;
  height: 2.4rem;
  background-color: $color-neutral-06;
  border: 0.2rem solid $color-neutral-03;
}

.icon {
  font-size: 2.4rem;
  display: none;
  fill: $color-neutral-06;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.input:checked + .checkbox {
  background-color: $color-neutral-01;
  border-color: $color-neutral-01;

  .icon {
    display: block;
  }
}

.label {
  margin: 0.1rem 0 0 1.6rem;
}
