@import '~theme/variables';

.imagesCardWrapper {
  margin-top: $space-s;
}

.image {
  display: block;
  margin: 0 auto $space-s;
  border-radius: $border-radius-03;
  filter: drop-shadow(0 5.9rem 2.3rem rgba(0, 0, 0, 0.01))
    drop-shadow(0 3.3rem 2rem rgba(0, 0, 0, 0.05))
    drop-shadow(0 1.4rem 1.4rem rgba(0, 0, 0, 0.09))
    drop-shadow(0 0.3rem 0.8rem rgba(0, 0, 0, 0.1))
    drop-shadow(0 0 0 rgba(0, 0, 0, 0.1));
  border: 0.1rem solid $color-neutral-06;
}

.copy {
  text-align: center;
  line-height: 160%;
  font-size: $body-font-size;
}

.right {
  text-align: right;
}

.center {
  text-align: center;
}
