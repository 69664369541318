@import '~theme/variables';

.container {
  display: flex;
  padding-top: $space-l;
  padding-bottom: $space-m;
  gap: 4rem;
  flex-direction: column;

  @include breakpoint(sm) {
    align-items: center;
    gap: $space-l;
    flex-direction: row-reverse;
  }
}

.appAndLogin {
  display: flex;
  gap: 4rem;
  flex-direction: column;
  align-items: start;

  @include breakpoint(sm) {
    align-items: center;
    gap: $space-l;
    flex-direction: row;
  }
}
