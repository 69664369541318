$color-primary: #f4b52c;
$color-secondary: #f4b52c;
$color-tertiary: #fbecb7;
$color-quaternary: #fbecb7;

$color-active: #5ae472;
$color-highlight: #eef9f3;
$color-success: #5ab789;
$color-success-02: #357957;
$color-error: #f4512c;
$color-popup: #fc1f09;
$color-utility: #754408;
$color-disabled: #535353;

$color-neutral-01: #100f0d;
$color-neutral-02: #6c6c71;
$color-neutral-03: #9f9fa3;
$color-neutral-04: #d0d0d0;
$color-neutral-05: #f2f2f2;
$color-neutral-06: #ffffff;

$color-soft-peach: #f5dcbd;
$color-soft-sand: #efe7da;
$color-soft-yellow: #fdf0d5;

$color-warning-200: #fef6f0;
$color-warning-500: #f57d20;

$color-n300: #e6e6e6;
