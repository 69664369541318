@import '~theme/variables';

.container {
  display: grid;
  grid-template-rows: min-content 1fr 50px min-content;
  position: relative;

  &:not(.isPanel) {
    @include breakpoint(sm) {
      grid-template-rows: min-content 50px auto 100px;
      grid-template-columns: 50px min-content 1fr;
    }
  }
}

.contactMap {
  grid-row: 2 / span 2;
  grid-column: 1;

  &:not(.isPanel) {
    @include breakpoint(sm) {
      grid-row: 2 / span 2;
      grid-column: 1 / span 3;
    }
  }
}

.contactCard {
  grid-row: 3 / span 2;
  grid-column: 1;
  width: calc(100% - 2rem);
  background: $color-neutral-01;
  padding: $space-s;
  margin: 0 auto;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include breakpoint(sm) {
    width: 34.3rem;
    &:not(.isPanel) {
      grid-column: 2;
      grid-row: 3 / span 2;
    }
  }

  a {
    color: $color-neutral-06;

    &:hover {
      color: $color-primary;
    }
  }
}

.contactCardContent {
  padding: $space-m $space-l;
}

.mapsLink {
  svg {
    fill: $color-primary;
  }
}

.contactItem {
  display: flex;
  gap: $space-xs;
  margin-bottom: $space-s;

  svg {
    fill: $color-neutral-06;
    width: 2rem;
    height: 2rem;
  }
}

.logoContainer {
  display: flex;
  justify-content: flex-end;
}

.contactLink {
  display: flex;
  align-items: center;
}
