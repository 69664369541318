@import '~theme/variables';

.section {
  overflow: hidden;

  @include breakpoint(sm, max) {
    margin: $space-l 0;
    border-top: 0.1rem solid $color-neutral-04;
    border-bottom: 0.1rem solid $color-neutral-04;

    > div {
      padding: $space-s 0;
    }
  }

  &.isPanel {
    margin: $space-l 0;
    border-top: 0.1rem solid $color-neutral-04;
    border-bottom: 0.1rem solid $color-neutral-04;

    > div {
      padding: $space-s 0;
    }
  }
}

.anchorList {
  display: flex;
  overflow-x: scroll;
  scrollbar-width: none;

  @include breakpoint(sm, max) {
    margin-left: -$space-s;
    margin-right: -$space-s;
    padding: $space-s;
  }

  .isPanel & {
    margin-left: -$space-s;
    margin-right: -$space-s;
    padding: $space-s;
  }
  &::-webkit-scrollbar {
    display: none;
  }
}

.anchorItem {
  display: flex;
  align-items: center;
  margin-left: 2.4rem;
  white-space: nowrap;
  p {
    font-size: $body-font-size;
    color: $body-color;
    text-decoration: underline;
    flex: 1;
    display: flex;
    &:hover {
      text-decoration: none;
    }
  }

  &:first-of-type {
    margin-left: 0;
  }

  &:last-of-type {
    margin-right: $space-s;
  }
}

.anchorLabel {
  margin-left: $space-s;
  p {
    text-decoration: none;
  }
}

.icon {
  fill: $color-primary;
  margin-right: 0.8rem;
}

.anchor {
  display: block;
  position: relative;
  top: -10rem;
  visibility: hidden;
}

.col {
  position: relative;
}

.arrowButtonWrapper {
  position: absolute;
  top: calc(50% - 2rem);

  & button {
    background-color: $color-neutral-06;
    border: solid 1px $color-neutral-03;
    height: 4rem;
    width: 4rem;
    position: relative;
    z-index: $z-index-above;

    & svg {
      fill: $color-neutral-03;
    }

    &:hover {
      background-color: $color-neutral-05;
    }
  }

  &.left {
    left: $space-s;

    &::before {
      content: '';
      position: absolute;
      height: 100%;
      z-index: $z-index-above;
      width: 5rem;
      left: -3rem;
      background: linear-gradient(
        to right,
        $color-neutral-06 10%,
        rgba(255, 255, 255, 0) 112.5%
      );
    }
  }

  &.right {
    right: $space-s;
    &::after {
      content: '';
      z-index: $z-index-above;
      position: absolute;
      height: 100%;
      top: 0;
      width: 5rem;
      right: -3rem;
      background: linear-gradient(
        to left,
        $color-neutral-06 10%,
        rgba(255, 255, 255, 0) 112.5%
      );
    }
  }
}
