@import '~theme/variables';

.bgSoftPeach {
  --section-bg-color: #{$color-soft-peach};
}

.bgSoftSand {
  --section-bg-color: #{$color-soft-sand};
}

.bgSoftYellow {
  --section-bg-color: #{$color-soft-yellow};
}

.sectionContent {
  padding: $space-xxl 0;

  &.forceMobileView {
    padding: $space-l 0;
  }

  &.hasBackground {
    padding: $space-xxl 0;
    background-color: var(--section-bg-color);
  }
}

.no-bottom-margin {
  margin-bottom: 0;
}

.decoratedBorder {
  display: none;
  width: 100vw;
  height: 2.2rem;

  path {
    fill: var(--section-bg-color);
  }

  &.top {
    margin-top: -2.2rem;
  }

  &.bottom {
    margin-bottom: -2.2rem;
  }

  @include breakpoint(md) {
    display: block;
  }
}
