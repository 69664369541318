@import '~theme/variables';

.footer {
  background-color: $color-neutral-01;
  padding-top: 2rem;

  @include breakpoint(sm) {
    padding-top: 4rem;
  }
}

.logoContainer {
  display: flex;
  justify-content: center;
  padding-top: 2rem;
  padding-bottom: 2rem;

  @include breakpoint(sm) {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
}

.logo {
  width: 28rem;

  @include breakpoint(sm) {
    width: 30rem;
  }
}

.bottomFooter {
  margin-top: 4rem;
  padding-bottom: 3.4rem;

  @include breakpoint(sm) {
    display: flex;
    align-items: center;
    margin-top: 9rem;
    padding-bottom: 7.5rem;
  }
}

.appButtonsColumn {
  display: flex;
  justify-content: flex-end;

  @include breakpoint(sm, max) {
    justify-content: flex-start;
  }
}

.legalMenuContainer {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: $space-xs;
  margin-bottom: 3.5rem;

  @include breakpoint(md) {
    margin-bottom: 7.5rem;
  }
}
