@import '~theme/variables';

@layer components {
  .container {
    width: 100%;
    max-width: $layout-max-container-width;
    margin: 0 auto;
    box-sizing: border-box;

    @each $size, $value in $layout-grid-offset {
      @include breakpoint($size) {
        padding-left: $value;
        padding-right: $value;
      }
    }

    &.forceMobileView {
      padding-left: map-get($layout-grid-offset, xs);
      padding-right: map-get($layout-grid-offset, xs);
    }

    &.noPadding {
      padding: 0;
    }
  }

  .row {
    display: flex;
    flex-wrap: wrap;

    @each $size, $value in $layout-grid-col-gap {
      @include breakpoint($size) {
        margin-left: -#{$value / 2};
        margin-right: -#{$value / 2};

        row-gap: #{$value / 2};
      }
    }

    &.has-no-vertical-reset {
      margin-top: 0;
      margin-bottom: 0;
    }

    &.reversed {
      flex-direction: row-reverse;
    }

    &.vertically-center {
      align-items: center;
    }

    &.no-gap {
      row-gap: 0;
    }

    @each $size, $value in $layout-breakpoints {
      &.reversed-#{$size} {
        @if $size == xs {
          @include breakpoint(sm, max) {
            flex-direction: row-reverse;
          }
        }

        // TODO: The layout npm package should contain a function to do this.
        @if $size == sm {
          @media (min-width: $value) and (max-width: map-get($layout-breakpoints, md) - 1) {
            flex-direction: row-reverse;
          }
        }

        @if $size == md {
          @media (min-width: $value) and (max-width: map-get($layout-breakpoints, lg) - 1) {
            flex-direction: row-reverse;
          }
        }

        @if $size == lg {
          @include breakpoint(lg) {
            flex-direction: row-reverse;
          }
        }
      }
    }
  }

  .column {
    box-sizing: border-box;
    width: 100%;

    @each $size, $value in $layout-grid-col-gap {
      @include breakpoint($size) {
        padding-left: $value / 2;
        padding-right: $value / 2;
      }
    }

    &.centered {
      display: flex;
      justify-content: center;
    }
  }

  @each $size, $value in $layout-breakpoints {
    @include breakpoint(#{$size}) {
      @for $i from 0 through $layout-grid-columns {
        .#{$size}-#{$i} {
          width: percentage($i / $layout-grid-columns);
        }

        .offset-#{$size}-#{$i} {
          margin-left: percentage($i / $layout-grid-columns);
        }
      }
    }
  }

  .noPadding {
    padding-top: 0;
  }

  .column {
    box-sizing: border-box;
    width: 100%;

    @each $size, $value in $layout-grid-col-gap {
      @include breakpoint($size) {
        padding-left: $value / 2;
        padding-right: $value / 2;
      }
    }

    &.centered {
      display: flex;
      justify-content: center;
    }
  }

  @each $size, $value in $layout-breakpoints {
    @include breakpoint(#{$size}) {
      @for $i from 0 through $layout-grid-columns {
        .#{$size}-#{$i} {
          width: percentage($i / $layout-grid-columns);
        }

        .offset-#{$size}-#{$i} {
          margin-left: percentage($i / $layout-grid-columns);
        }
      }
    }
  }

  .noPadding {
    padding-top: 0;
    padding-bottom: 0;
  }

  .hasBgColor {
    padding: $space-m $space-xs;
    border-radius: $border-radius-04;
    margin-left: -$space-xs;
    margin-right: -$space-xs;

    @include breakpoint(sm) {
      margin-left: 0;
      margin-right: 0;
      padding-top: $space-xl;
      padding-bottom: $space-xl;
    }

    &.stickToBottom {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      padding-bottom: 0;
    }

    .hasBgColor {
      padding: $space-m $space-xs;
      border-radius: 24px;
      margin-left: -$space-xs;
      margin-right: -$space-xs;

      @include breakpoint(sm) {
        margin-left: 0;
        margin-right: 0;
        padding-top: $space-xl;
        padding-bottom: $space-xl;
      }

      &.stickToBottom {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        padding-bottom: 0;
      }

      &.stickToTop {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }

      &.softPeach {
        background-color: $color-soft-peach;
      }

      &.softSand {
        background-color: $color-soft-sand;
      }

      &.softYellow {
        background-color: $color-soft-yellow;
      }
    }
  }
}
