@import "~theme/variables";

.stepsCardWrapper {
  border: 0.1rem solid #D0D0D0;
  box-shadow: 0 0.6rem 1.6rem rgba(16, 15, 13, 0.08);
  border-radius: 1.6rem;
  padding: $space-m;
  margin-bottom: $space-l;
  z-index: $z-index-above;
  background: $color-neutral-06;
}

.title {
  margin-bottom: $space-xs;
  line-height: 135%;
}

.subtitle {
  line-height: 160%;
  font-size: $body-font-size;
}

.divider {
  margin: $space-m 0;
  border: 0.1rem solid $color-neutral-04;
  width: 100%;
}

.stepCounter {
  background: #FFFFFF;
  border: 0.2rem solid $color-primary;
  padding: 1.5rem 1.3rem 1.3rem 1.3rem;
  display: flex;
  border-radius: 3rem;
  justify-content: center;
  align-items: center;
  height: 4rem;
  width: 4rem;
  font-size: 1.8rem;
  color: $color-primary;
  font-weight: 700;
  margin-right: $space-l;
}

.stepWrapper {
  display: flex;
}

.button {
  display: inline;

  + .button {
    margin-left: 1.6rem;
  }
}

.buttons {
  display: flex;
  padding-top: 1.6rem;

  a {
    width: 13.5rem;
    height: 4rem;
  }
}
