@import '~theme/variables';

.container {
  gap: $space-xs;
  padding: $space-s;
  display: flex;
  top: $space-m;
  right: $space-m;
  flex-direction: column;
}

.controlButton {
  width: 5.2rem;
  height: 5.2rem;
  border-radius: $border-radius-03;
  background: $color-neutral-06;
  box-shadow: $box-shadow-01;

  display: flex;
  align-items: center;
  justify-content: center;

  @include breakpoint(sm) {
    border-radius: $border-radius-01;
    width: 4rem;
    height: 4rem;
  }

  .icon {
    width: 1.6rem;
    height: 1.6rem;
  }

  .locationIcon {
    width: 2.4rem;
    height: 2.4rem;
  }

  &.mobileHidden {
    display: none;

    @include breakpoint(sm) {
      display: flex;
    }
  }
}
