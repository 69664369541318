@import "~theme/variables";

.attribute {
  display: flex;
  align-items: center;
  padding: $space-xxs $space-xs;
  border: 0.1rem solid;
  border-radius: $space-xs;
  background: $color-neutral-06;

  p {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  svg {
    margin-right: $space-xxs;
    font-size: $h3-font-size;
  }

  &.greyBg {
    background: $color-neutral-05;
    border: 0;
  }
}