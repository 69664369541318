@import '~theme/variables';

.cardFooter {
  background-color: $color-neutral-05;
  padding: $space-xs $space-s;
  flex: 1;
  font-size: $small-font-size;
  line-height: $small-line-height;
  white-space: pre-wrap;
  border-radius: 0 0 1.5rem 1.5rem;

  @include breakpoint('sm') {
    font-size: $body-font-size;
    padding: 1.2rem $space-m;
  }
}
