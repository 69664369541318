@import '~theme/variables';

.footer {
  border-radius: 0 0 $space-s $space-s;
  display: flex;

  @include breakpoint(sm) {
    height: 100%;
  }
}

.uspsContainer {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.imageWrapper {
  width: 6rem;
  margin-left: auto;
}
