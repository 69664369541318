@import '~theme/variables';

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .menu {
    align-items: center;
    margin: 1.6rem 0;
    display: flex;
    flex-wrap: wrap;

    a {
      margin-right: $space-s;
      gap: $space-s;
    }

    .crumb {
      line-height: 1;
    }

    @include breakpoint(sm) {
      margin: 2.4rem 0;
    }

    svg {
      transform: rotate(-90deg);
      height: 1rem;
      font-size: 1rem;

      path {
        fill: $color-primary;
      }
    }

    a:last-child {
      span {
        color: $color-neutral-01;
        font-style: normal;
        font-weight: 400;
      }

      svg {
        display: none;
      }
    }
  }
}
