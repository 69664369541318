@import '~theme/variables';

.container {
  display: flex;
  justify-content: center;
  gap: $space-xxs;
  padding-top: $space-m;
  padding-bottom: $space-m;

  @include breakpoint(sm) {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
}

.link {
  padding: $space-xs;

  &:hover svg {
    fill: $color-primary;
  }
}

.icon {
  width: 1.8rem;
  height: 1.8rem;
  fill: white;
  transition: fill $transition-01;
}
