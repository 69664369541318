@import '~theme/variables';

.title {
  margin-bottom: 2.4rem;
}

.list {
  display: grid;
  gap: 2.4rem;

  @include breakpoint('sm') {
    grid-template-columns: 1fr 1fr;
  }
}
