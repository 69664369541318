@import '~theme/variables';

.container {
  position: relative;
  width: 100%;
}

.input {
  display: inline-block;
  padding: 1.2rem;
  width: 100%;
  background-color: $color-neutral-05;
  border: 0.1rem solid transparent;
  border-radius: $border-radius-03;
  caret-color: $color-primary;
  font-family: inherit;
  line-height: inherit;
  font-size: inherit;

  &:focus {
    outline: none;
    background-color: $color-neutral-06;
    border-color: $color-neutral-05;
  }

  &::placeholder {
    color: $color-neutral-02;
  }

  &:disabled {
    background-color: $color-neutral-04;
    color: $color-disabled;
  }
}

.input.search {
  line-height: 2.8rem;
  border-radius: $border-radius-03;
}

.input.error,
.input:focus.error {
  border-color: $color-error;
}

.input.light {
  background-color: $color-neutral-06;

  &::placeholder {
    color: $color-neutral-03;
  }
}

.input.alternative {
  padding: 1.2rem 2.4rem;
  box-shadow: $box-shadow-01;
}

.search-button {
  position: absolute;
  top: 50%;
  right: 1.6rem;
  transform: translateY(-50%);
  display: flex;
}

.icon {
  fill: $color-neutral-03;
  cursor: pointer;
  font-size: 2rem;
}
