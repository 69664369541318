@import '~theme/variables';

.sectionGrid {
  display: flex;
  flex-direction: column;
  gap: $space-s;

  @include breakpoint('md') {
    gap: $space-m;
    min-height: 70rem;
    display: grid;
    grid-template-columns: 2fr 1fr;
  }

  @include breakpoint('md') {
    display: grid;
    grid-template-columns: 1fr 2fr;
  }
}

.content {
  overflow: hidden;
  border-radius: 2.4rem;
  padding: $space-m;
  background: $color-neutral-01;
  color: $color-neutral-06;
}

.title {
  color: $color-neutral-06;
  margin-bottom: $space-m;
}

.button {
  color: $color-neutral-06;
  border-color: $color-neutral-06;

  &:hover {
    background-color: $color-neutral-06;
    color: $color-neutral-01;
  }
}

.listItem {
  list-style: none;
  display: flex;
  align-items: center;
  gap: $space-s;
  margin-left: -2.8rem;

  & svg {
    & path {
      fill: $color-primary;
    }
  }
}

.imageGrid {
  display: grid;
  gap: $space-s;
  aspect-ratio: 3/7;
  grid-template-rows: min-content 1fr 1fr min-content;
  grid-template-areas:
    'a a'
    'c d'
    'e d'
    'f f';

  @include breakpoint('md') {
    gap: $space-m;
    aspect-ratio: auto;
    display: grid;
    grid-template-rows: auto;
    grid-template-areas:
      'a a a a'
      'a a a a'
      'a a a a'
      'a a a a'
      'a a a a'
      'a a a a'
      'a a a a'
      'a a a a'
      'c c d d'
      'c c d d'
      'c c d d'
      'c c d d'
      'c c d d'
      'e e d d'
      'e e d d'
      'e e d d'
      'e e d d'
      'e e d d';
  }

  @include breakpoint('lg') {
    display: grid;
    grid-template-areas:
      'a a a a b b b'
      'a a a a b b b'
      'a a a a b b b'
      'a a a a b b b'
      'a a a a b b b'
      'a a a a b b b'
      'a a a a b b b'
      'a a a a b b b'
      'c c d d b b b'
      'c c d d b b b'
      'c c d d b b b'
      'c c d d b b b'
      'c c d d f f f'
      'e e d d f f f'
      'e e d d f f f'
      'e e d d f f f'
      'e e d d f f f'
      'e e d d f f f';
  }

  & > div {
    overflow: hidden;
    border-radius: 2.4rem;
    position: relative;
  }
}

.item-a {
  grid-area: a;
  aspect-ratio: 7/4;

  @include breakpoint('md') {
    aspect-ratio: auto;
    display: none;
  }

  @include breakpoint('md') {
    display: block;
  }
}

.item-b {
  grid-area: b;
  display: none;

  @include breakpoint('lg') {
    display: block;
  }
}

.item-c {
  grid-area: c;

  @include breakpoint('md') {
    display: none;
  }

  @include breakpoint('md') {
    display: block;
  }
}

.item-d {
  grid-area: d;

  @include breakpoint('md') {
    display: none;
  }

  @include breakpoint('md') {
    display: block;
  }
}

.item-e {
  grid-area: e;

  @include breakpoint('md') {
    display: none;
  }

  @include breakpoint('md') {
    display: block;
  }
}

.item-f {
  grid-area: f;
  aspect-ratio: 7/4;

  @include breakpoint('md') {
    aspect-ratio: auto;
    display: none;
  }

  @include breakpoint('lg') {
    display: block;
  }
}

.image {
  object-fit: cover;
}

.video {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 2.4rem;
}
