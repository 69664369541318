@import '~theme/variables';

@layer components {
  .container {
    width: 100%;
    max-width: $layout-max-container-width;
    margin: 0 auto;
    box-sizing: border-box;

    @each $size, $value in $layout-grid-offset {
      @include breakpoint($size) {
        padding-left: $value;
        padding-right: $value;
      }
    }
  }

  .row {
    display: flex;
    flex-wrap: wrap;

    @each $size, $value in $layout-grid-col-gap {
      @include breakpoint($size) {
        margin-left: -#{$value / 2};
        margin-right: -#{$value / 2};
      }
    }

    @each $size, $value in $layout-grid-row-gap {
      @include breakpoint($size) {
        margin-top: -#{$value / 2};
        margin-bottom: -#{$value / 2};
      }
    }

    &.has-no-vertical-reset {
      margin-top: 0;
      margin-bottom: 0;
    }

    &.reversed {
      flex-direction: row-reverse;
    }

    &.vertically-center {
      align-items: center;
    }

    @each $size, $value in $layout-breakpoints {
      &.reversed-#{$size} {
        @if $size == xs {
          @include breakpoint(sm, max) {
            flex-direction: row-reverse;
          }
        }

        // TODO: The layout npm package should contain a function to do this.
        @if $size == sm {
          @media (min-width: $value) and (max-width: map-get($layout-breakpoints, md) - 1) {
            flex-direction: row-reverse;
          }
        }

        @if $size == md {
          @media (min-width: $value) and (max-width: map-get($layout-breakpoints, lg) - 1) {
            flex-direction: row-reverse;
          }
        }

        @if $size == lg {
          @include breakpoint(lg) {
            flex-direction: row-reverse;
          }
        }
      }
    }
  }

  .column {
    box-sizing: border-box;
    width: 100%;

    @each $size, $value in $layout-grid-col-gap {
      @include breakpoint($size) {
        padding-left: $value / 2;
        padding-right: $value / 2;
      }
    }

    @each $size, $value in $layout-grid-row-gap {
      @include breakpoint($size) {
        padding-top: $value / 2;
        padding-bottom: $value / 2;
      }
    }

    &.centered {
      display: flex;
      justify-content: center;
    }
  }

  @each $size, $value in $layout-breakpoints {
    @include breakpoint(#{$size}) {
      @for $i from 0 through $layout-grid-columns {
        .#{$size}-#{$i} {
          width: percentage($i / $layout-grid-columns);
        }

        .offset-#{$size}-#{$i} {
          margin-left: percentage($i / $layout-grid-columns);
        }
      }
    }
  }
}
