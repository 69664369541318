@import '~theme/variables';

.content {
  border-radius: $border-radius-03;
  border: 1px solid $color-primary;
  background: $color-tertiary;
  padding: $space-m;

  margin-left: $space-s;
  margin-right: $space-s;

  @include breakpoint(md) {
    padding: $space-l;
    margin-left: $space-xl;
    margin-right: $space-xl;
  }

  @include breakpoint(lg) {
    margin-left: $space-xxl;
    margin-right: $space-xxl;
  }
}

.listItem {
  display: flex;
  align-items: flex-start;
  margin-top: $space-xs;

  @include breakpoint(sm) {
    margin-top: $space-s;
    span {
      font-size: $h4-font-size;
      font-style: normal;
      font-weight: 400;
    }
  }

  span {
    font-style: normal;
    font-weight: 400;
    text-decoration: underline;
    text-align: left;
  }

  p {
    margin-right: 2rem;
    font-size: $h4-font-size;
  }
}

.link {
  text-align: start;
  span {
    line-height: 160%;
  }
}
