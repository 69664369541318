@import '~theme/variables';

.container {
  position: relative;
  height: 32rem;
  width: 100%;

  border-radius: $border-radius-03;
  position: relative;
  overflow: hidden;

  display: flex;
  align-items: flex-end;
  justify-content: center;
  overflow: hidden;

  > button {
    position: relative;
    margin: $space-s;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  @include breakpoint(sm) {
    height: 31.3rem;
  }

  @include breakpoint(md) {
    height: 29.6rem;
  }
}
.image {
  object-position: center;
  object-fit: cover;
}
