@import '~theme/variables';

.item {
  transition: background 160ms ease-out;
  border-bottom: 1px solid $color-neutral-04;

  @media (hover) {
    &:hover {
      background: $color-neutral-05;
    }
  }

  &:last-of-type {
    margin-bottom: 0;
  }
}

.trigger {
  padding: 2.4rem 1.6rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  font-size: $body-font-size;
  gap: 1.2rem;
}

.indicator {
  color: $color-primary;
}

.content {
  padding: 0 4rem 2.4rem 1.6rem;
  font-size: $large-font-size;
  line-height: $large-line-height;
  margin-bottom: 0;
}
