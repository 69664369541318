@import "~theme/variables";

.container {
  position: relative;
}

.viewBox {
  pointer-events: none;
  position: absolute;
  top: -12rem;
  left: 0;
  width: 100%;
  height: 100rem;
  overflow: hidden;
}

.curlyLine {
  position: absolute;
  top: 0;
  z-index: $z-index-background;
  display: none;

  @include breakpoint(sm) {
    display: block;

    > path {
      stroke-width: 8;
    }
  }

  @include breakpoint(md, max) {
    top: 42rem;
    transform: scaleX(1.75) scaleY(1.75);
  }
}
