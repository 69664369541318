@import '~theme/variables';

.toogleButton {
  text-decoration: none;
  span {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      fill: $color-primary;
      font-size: $button-font-size;
      margin-left: $space-xs;
    }
  }
}

.colCentered {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: $space-s;
}

.tabRow {
  margin-bottom: 4rem;
}
