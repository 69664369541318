@import '~theme/variables';

.row {
  gap: $space-m;

  &:not(.isPanel) {
    @include breakpoint(sm) {
      gap: unset;
    }
  }
}

.textContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;

  h1 {
    word-break: break-word;

    @media only screen and (max-width: 800px) {
      font-size: 6rem;
    }

    @include breakpoint(md) {
      font-size: 9.6rem;
    }

    @include breakpoint(lg) {
      font-size: 11.2rem;
    }
  }

  &.isPanel h1 {
    font-size: 6rem;
  }

  @include breakpoint(sm) {
    &:not(.isPanel) {
      padding: 0 0 0 4.6rem !important;
    }
  }

  @include breakpoint(lg) {
    &:not(.isPanel) {
      padding: 0 !important;
    }
    margin-left: auto;
  }

  > :nth-child(n + 2) {
    margin-top: 2.4rem;

    @include breakpoint(sm) {
      margin-top: 3.2rem;
    }
  }
}

.column {
  display: flex;
  flex-direction: column;
}

.infoButtonsContainer {
  display: flex;
  gap: $space-m;
  flex-wrap: wrap;
  flex-direction: column;

  .isPanel & {
    gap: 0;

    @include breakpoint(sm, max) {
      margin-top: $space-s;
    }
  }
}

.buttonsContainer {
  display: flex;

  .isPanel & {
    margin-left: 0;
    width: 50%;
  }

  :nth-child(n + 2) {
    margin-left: 1.6rem;
  }

  @include breakpoint(sm, max) {
    width: 100%;
  }
}

.infoContainer {
  display: flex;

  @include breakpoint(sm, max) {
    flex-wrap: wrap;
    gap: $space-s;
  }

  p {
    @include breakpoint(sm, max) {
      font-size: $body-font-size;
    }
  }

  .isPanel & {
    flex-wrap: wrap;
    gap: $space-s;

    p {
      font-size: $body-font-size;
    }
  }
}

.openingHours {
  margin-left: 3.4rem;
  @include breakpoint(sm) {
    margin-left: 5.2rem;
  }
}

.selectClubButton {
  @include breakpoint(sm, max) {
    width: 100%;
  }
}

.imageContainer {
  padding: 0;
  position: relative;
  width: 100%;
  height: 52.5rem;
  max-width: 55rem;
  background-color: $color-neutral-05;
  border-radius: $border-radius-03;
  overflow: hidden;

  @include breakpoint(lg, max) {
    height: 47rem;
  }

  @include breakpoint(md, max) {
    height: 44.2rem;
  }

  @media only screen and (max-width: 924px) {
    height: 41.7rem;
  }

  @media only screen and (max-width: 824px) {
    height: 37.2rem;
  }

  @include breakpoint(sm, max) {
    height: 64rem;
    max-width: 100%;
  }

  @media only screen and (max-width: 724px) {
    height: 56rem;
  }

  @media only screen and (max-width: 624px) {
    height: 50rem;
  }

  @media only screen and (max-width: 524px) {
    height: 42.2rem;
  }

  @media only screen and (max-width: 424px) {
    height: 37.2rem;
  }

  @media only screen and (max-width: 380px) {
    height: 32.7rem;
  }

  &.isPanel {
    height: 28.6rem;
    max-width: none;

    @include breakpoint(sm) {
      height: 32.7rem;
    }
  }
}

.mainImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: filter 0.2s ease-in-out;

  &:hover {
    filter: brightness(0.88);
  }
}

.isClickable {
  cursor: pointer;
}

.imageButtons {
  position: absolute;
  bottom: $space-m;
  left: $space-m;
}

.openingSoon {
  color: $color-warning-500;
}

.joinButton {
  @include breakpoint(sm, max) {
    padding-left: 1.2rem;
    padding-right: 1.2rem;
  }
}
