@import '~theme/variables';

.title {
  text-align: center;
}

.description {
  margin-top: $space-s;
  text-align: center;
}

.row {
  margin-bottom: $space-l;
}

.footer {
  display: flex;
  justify-content: center;
}
