.button {
  display: inline-flex;

  + .button {
    margin-left: 1.6rem;
  }

  img {
    display: block;
  }
}
