@import '~theme/variables';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $space-l;

  @include breakpoint(md) {
    gap: $space-xl;
  }
}

.title {
  max-width: 62rem;
  text-align: center;
}

.buttonsContainer {
  display: flex;
  justify-content: center;
  gap: $space-s;
}

.button {
  @include breakpoint(md, max) {
    padding: 1.6rem;
    & span {
      display: none;
    }
  }
}
