@import '~theme/variables';

.headerImageWrapper {
  position: relative;
  height: 30rem;
  display: flex;
  justify-content: center;
}

.imageContent {
  position: absolute;
  height: 100%;
  width: 44.4rem;
  img {
    border: 0.1rem solid $color-neutral-06;
    margin-bottom: $space-s;
    border-radius: $border-radius-03;
    position: absolute;
    box-shadow: $box-shadow-01;
    border: 0.1rem solid $color-neutral-06;
    width: 10rem;
    height: 13rem;
  }
}

$column-1: 0rem;
$column-2: 10rem;
$column-3: 21.4rem;
$column-4: 33rem;

.animationContainer {
  top: 10.6rem;
  left: $column-2;
  margin-top: $space-s;
  margin-left: $space-s;
  position: absolute;
  height: 12.1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.image1 {
  position: absolute;
  top: -1.5rem;
  left: $column-1;
}

.image2 {
  top: -1.5rem;
  left: $column-2;
  margin-left: $space-s;
}

.image3 {
  top: -8rem;
  margin-left: $space-s;
  left: $column-3;
}

.image4 {
  top: -4rem;
  left: $column-4;
  margin-left: $space-s;
}

.image5 {
  top: 10.6rem;
  left: $column-1;
  margin-top: $space-s;
}

.image6 {
  top: 4rem;
  left: $column-3;
  margin-top: $space-s;
  margin-left: $space-s;
}

.image7 {
  top: 8rem;
  left: $column-4;
  margin-top: $space-s;
  margin-left: $space-s;
}
