@import '~theme/variables';

$error: #af2809;
$error-bg: #fcd7cf;

@layer components {
  .container {
    display: flex;
    align-items: center;
    border-radius: $border-radius-03;
    padding: $space-s;
    gap: $space-xs;
    border: 0.1rem solid;
    margin: $space-l 0;

    &.warning {
      border-color: $color-warning-500;
      background-color: $color-warning-200;
      .icon {
        fill: $color-warning-500;
      }
    }

    &.info {
      border-color: $color-primary;
      .icon {
        fill: $color-primary;
      }
    }

    &.error {
      border-color: $error;
      background-color: $error-bg;
      .icon {
        fill: $error;
      }
      .note {
        color: $error;
      }
    }

    &.neutral {
      border: none;
      background-color: $color-neutral-05;
    }
  }

  .icon {
    width: 2rem;
    height: 2rem;
  }
  .note {
    font-size: $small-font-size;
  }

  .noteTitle {
    font-weight: 700;
  }
}
