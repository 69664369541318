@import '~theme/variables';

.title {
  margin-bottom: 3.2rem;
}

.list {
  list-style: none;
  counter-reset: item;

  li {
    position: relative;
    counter-increment: item;
    margin-bottom: 1.6rem;
    padding-left: 6.4rem;

    &:before {
      content: counter(item);
      position: absolute;
      left: 0;
      font-size: $h4-font-size;
      font-weight: 600;
      border-radius: 100%;
      color: $color-primary;
      border: 2px solid $color-primary;
      width: 4rem;
      height: 4rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
