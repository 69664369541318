@import '~theme/variables';

.container {
  position: relative;
  z-index: $z-index-tooltip;
  display: flex;
  align-items: center;
  // justify-content: space-between;
  width: 100%;
  padding: 1.2rem 1.6rem;
  margin-bottom: 1.6rem;

  @include breakpoint(sm) {
    padding: 1.2rem 2.4rem;
  }

  &.black {
    background: $color-neutral-01;

    .icon,
    .closeIcon {
      fill: $color-neutral-06;
    }

    &::after {
      background-image: url('../../../../../public/static/images/shared/top-banner-stripes-black.svg');
    }
  }

  &.yellow {
    background: $color-primary;

    .icon,
    .closeIcon {
      fill: $color-neutral-01;
    }

    &::after {
      background-image: url('../../../../../public/static/images/shared/top-banner-stripes-primary.svg');
    }
  }

  &:after {
    content: '';
    position: absolute;
    bottom: -15px;
    left: -3%; // Safari is creating a small gap on the left side
    width: 107%;
    height: 22px; // original height of the image
    background-size: cover;
    background-repeat: no-repeat;
    z-index: $z-index-header;
  }
}

.wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  font-size: 24px;
  margin-right: 1.2rem;
  display: block;

  @include breakpoint(sm, max) {
    font-size: 34px;
  }
}

.description {
  p {
    @include breakpoint(sm, max) {
      font-weight: 400;
    }
  }
  a {
    text-decoration: underline;
  }

  &.yellow {
    a:hover {
      color: $color-neutral-06;
    }
  }
}

.closeIcon {
  display: flex;
  margin-left: 1.6rem;
  align-items: center;
  justify-self: center;

  & svg {
    font-size: 1.6rem;
  }
}
