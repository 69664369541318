@import '~theme/variables';

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $space-xs $space-s;
  color: $color-neutral-02;
}

.main {
  padding: 0 $space-s $space-s;
}

.closeButton {
  display: flex;
  align-items: center;
  padding: $space-xs;
  border-radius: $border-radius-01;
  transition: background-color $transition-01;

  &:hover {
    background: $color-neutral-05;
  }

  & svg {
    width: 1.6rem;
    height: 1.6rem;
    fill: $color-neutral-02;
  }
}
