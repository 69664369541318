@import '~theme/variables';

.link {
  display: inline-flex;
  align-items: center;
  gap: $space-xs;
  flex-direction: row-reverse;

  & svg {
    fill: $color-primary;
  }
}

.light {
  color: $color-neutral-06;
}
