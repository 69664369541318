@import '~theme/variables';

.container {
  padding: 2.4rem;
  box-shadow: $box-shadow-01;
  border-radius: $border-radius-03;
  border: 0.1rem solid $color-neutral-05;
  background: $color-neutral-06;

  > :nth-child(n + 4) {
    margin-top: 1.6rem;
  }
}

.price {
  font-size: 2.6rem;
  font-weight: 500;
  margin-top: -1.4rem;
  line-height: 125%;
}

.footer {
  display: flex;
  justify-content: space-between;
}

.priceContainer {
  display: flex;
  align-items: flex-end;
}

.horizontalRule {
  color: $color-neutral-04;
  background-color: $color-neutral-04;
  border: none;
  height: 1px;
}

.startingFrom {
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 2.5;
}

.priceDescription {
  margin-left: 0.4rem;
}

.fraction {
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 1.7;
}

.capitalize:first-letter {
  text-transform: capitalize;
}
