@import "~theme/variables";

.linkColumn {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.link {
  width: 100%;
  height: 4.8rem;
  font-weight: $body-font-weight;

  @include breakpoint(md) {
    width: 50%;
    height: 3.2rem;
  }

  @include breakpoint(lg) {
    width: 33%;
  }

  span {
    font-weight: 200;
  }

  &.fullWidth {
    @include breakpoint(sm) {
      width: 50%;
    }

    @include breakpoint(md) {
      width: 33%;
    }
    @include breakpoint(lg) {
      width: 25%;
    }
  }
}

.subtitle {
  margin-bottom: $space-m;
  margin-top: $space-l;

  @include breakpoint(sm, max) {
    margin-bottom: $space-s;
  }
}

.plusIcon {
  fill: $color-primary;
  margin-right: $space-xs;
}

.collapseButton {
  height: 3.6rem;
  background-color: transparent;
  color: $color-neutral-01;
  display: flex;
  align-items: center;

  &:hover {
    background: transparent;
    color: $color-neutral-01;
  }
}