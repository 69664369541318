@import "~theme/variables";

.title {
  text-align: center;
  margin-bottom: $space-l;
}

.expireWarning {
  text-align: center;
  font-size: $large-font-size;
  margin-bottom: $space-xl;
}

.subtitle {
  margin-bottom: $space-s;
  text-align: center;
}

.question {
  margin-bottom: $space-xs;
  line-height: 135%;
}

.svg {
  position: absolute;
  top: 90%;
  left: 50%;
  translate: -50% -50%;
  height: calc(100vh - 7rem);
  min-width: 100vw;
  z-index: $z-index-background;
  background-color: transparent;
  @include breakpoint(sm, max) {
    display: none;
  }
}

.answer {
  line-height: 160%;
}

.inviteeName {
  position: relative;
  &::after {
    content: '';
    position: absolute;
    bottom: -1rem;
    left: 0;
    transform: translate(-50%, -50%);
    width: 100%;
    transform: scale(1.5);
    min-width: 8rem;
    height: 1.8rem;
    z-index: -1;
    background-image: url('/static/images/sportcity/illustrations/underline.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
  }
}
.colWrapper {
  margin-top: 1.2rem;
}

.clubsLink {
  color: $color-neutral-01;
  text-decoration: underline;
  cursor: pointer;
}