@import '~theme/variables';

.clubCard {
  position: relative;
  display: flex;
  width: 100%;
  overflow: hidden;
  border-radius: $border-radius-03;
  border: 1px solid $color-neutral-04;
  text-align: left;
  flex-direction: column;
  font-size: 1.4rem;
  cursor: pointer;

  &:hover {
    border-color: $color-neutral-03;
  }
}

.main {
  width: 100%;
  padding: 16px;
  gap: 1.6rem;
}

.title {
  font-size: 1.8rem;
  font-weight: 700;
  margin-bottom: 0.4rem;
  line-height: 1;
}

.address {
  padding-bottom: 1.6rem;
  font-style: normal;

  display: flex;
  justify-content: space-between;
  gap: 1.6rem;
}

.price {
  font-weight: 700;
  font-size: 1.6rem;
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.2rem;
}

.button {
  font-weight: 700;
  font-size: 1.6rem;
}

.detailsLink {
  text-decoration: underline;
}

.openingSoon {
  background-color: $color-neutral-01;
  color: $color-neutral-06;
  font-weight: 700;
  line-height: 1.5;
  font-size: 1.6rem;
  padding: $space-xs;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 1;
}
