@import '~theme/variables';

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  box-shadow: $box-shadow-01;
  border-radius: $border-radius-03;
  overflow: hidden;
  height: 30rem;
  width: 100%;
  margin-bottom: $space-xxl;
  transition: transform 0.2s ease-in-out;
  &:focus,
  &:hover {
    transform: translateY(-0.4rem);
  }

  @include breakpoint(sm, max) {
    height: 28rem;

    h4 {
      margin-bottom: 0.4rem;
    }

    h4,
    p {
      line-height: 1;
    }
  }
}

.header {
  position: relative;
  width: 100%;
  padding-bottom: 47%;
  background-color: $color-neutral-05;
  border-radius: $border-radius-03;
  transition: filter 0.2s ease-in-out;
}

.image {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  object-fit: cover;
  transition: transform $transition-01;
}

.body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  padding: $space-s $space-s;
  text-align: left;
  background-color: $color-neutral-06;
  flex-grow: 1;

  @include breakpoint(sm) {
    padding: $space-s;
  }
}

.footer {
  display: flex;
  align-items: center;
}

.marker {
  fill: $color-neutral-02;
  font-size: $space-s;
  margin-right: $space-xs;
}

.description {
  margin-top: $space-s;
  flex-grow: 1;
}
