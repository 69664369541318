@import '~theme/variables';

.aboutContainer {
  margin: 0;

  @include breakpoint(sm) {
    margin: 7rem 0 $space-l;
  }

  &.isPanel {
    margin: 0;
    margin-bottom: $space-l;
  }
}

.description {
  margin-top: $space-s;

  @include breakpoint(sm) {
    margin-top: $space-l;

    &.isPanel {
      margin-top: $space-s;
    }
  }

  a {
    color: $color-neutral-01;
    font-size: $h4-font-size;
    font-weight: 700;
    text-decoration: underline;
  }

  > p {
    margin: $space-s 0;
    color: $color-neutral-02;

    @include breakpoint(sm, max) {
      font-size: $h5-font-size;
    }
  }
}

.expandedDescription {
  > p {
    margin: $space-s 0;
    color: $color-neutral-02;

    @include breakpoint(sm, max) {
      font-size: $h5-font-size;
    }
  }
}

.toggle {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: $space-xs;
  margin-top: $space-s;

  transition: text-decoration-color $transition-01;
  text-decoration: underline 0.1rem rgba(255, 255, 255, 0);
  text-underline-offset: $space-xs;

  &:hover {
    text-decoration-color: $color-neutral-01;
  }
}

.toggle-icon {
  fill: $color-primary;
}
