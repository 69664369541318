$z-index-background: -1;
$z-index-normal: 0;
$z-index-above: 1;
$z-index-header: 10;
$z-index-logo: 11;

$z-index-sheet-overlay: 19;
$z-index-sheet: 20;

$z-index-dialog-overlay: 99;
$z-index-dialog: 100;
$z-index-dialog-content: 120;
$z-index-tooltip: 150;
