@import '~theme/variables';

.marker {
  svg {
    transition: transform $transition-01, fill $transition-01;
    &:hover {
      transform: scale(1.1);
      g > g:nth-child(2) > path {
        fill: $color-neutral-02;
      }
    }
  }
}

.cluster {
  &:hover {
    svg {
      transform: scale(1.1);
      circle {
        fill: $color-neutral-04;
        stroke: $color-neutral-04;
      }
    }

    span {
      transform: scale(1.1);
    }
  }
  svg {
    transition: transform $transition-01, fill $transition-01;
  }

  span {
    transition: transform $transition-01;
  }
}
