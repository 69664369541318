$font-family-primary: var(--font-din), sans-serif;
$font-family-display: var(--font-hackney), sans-serif;

$h1-font-size: 3.8rem;
$h1-line-height: 1.1;
$h1-font-weight: 500;
$h1-color: $color-neutral-01;
$h1-font-size-large: 4.8rem;

$h2-font-size: 2.6rem;
$h2-line-height: 1.25;
$h2-font-weight: 500;
$h2-color: $color-neutral-01;
$h2-font-size-large: 3.2rem;

$h3-font-size: 2rem;
$h3-line-height: 1.32;
$h3-font-weight: 500;
$h3-color: $color-neutral-01;
$h3-font-size-large: 2.6rem;

$h4-font-size: 1.8rem;
$h4-line-height: 1.42;
$h4-font-weight: 500;
$h4-color: $color-neutral-01;
$h4-font-size-large: 2.2rem;

$h5-font-size: 1.6rem;
$h5-line-height: 1.5;
$h5-font-weight: bold;
$h5-color: $color-neutral-01;
$h5-font-size-large: 1.8rem;

$price-font-size: 1.8rem;
$price-line-height: 1.25;
$price-font-weight: 800;
$price-color: $color-neutral-01;

$button-font-size: 1.6rem;
$button-line-height: 1;
$button-font-weight: bold;
$button-color: inherit;

$link-font-size: 1.6rem;
$link-line-height: 1;
$link-font-weight: 500;
$link-color: inherit;

$large-font-size: 1.8rem;
$large-line-height: 1.6;
$large-font-weight: normal;
$large-color: $color-neutral-01;

$body-font-size: 1.6rem;
$body-line-height: 1.6;
$body-font-weight: normal;
$body-color: $color-neutral-01;

$small-font-size: 1.4rem;
$small-line-height: 1.75;
$small-font-weight: 400;
$small-color: $color-neutral-01;

$note-font-size: 1.3rem;
$note-line-height: 1.6;
$note-font-weight: normal;
$note-color: $color-neutral-01;

$section-heading-font-size: 2.1rem;
$section-heading-line-height: 1.42;
$section-heading-font-weight: 500;
$section-heading-color: $color-neutral-01;

$club-heading-font-family: $font-family-display;
$club-heading-font-size: 6.8rem;
$club-heading-line-height: 80%;
$club-heading-font-weight: 700;
$club-heading-color: $color-neutral-01;
$club-heading-font-size-large: 11.6rem;

$club-heading-font-family: $font-family-display;
$club-heading-font-size: 6.8rem;
$club-heading-line-height: 80%;
$club-heading-font-weight: 700;
$club-heading-color: $color-neutral-01;
$club-heading-font-size-large: 11.6rem;

$club-section-heading-size-large: 8rem;
$club-section-heading-size: 4rem;
$club-section-heading-size-small: 3rem;
