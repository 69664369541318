@import '~theme/variables';

.container {
  border: 1px solid $color-neutral-04;
  border-radius: $border-radius-03;
  padding: 2.4rem;
}

.title {
  margin-bottom: 1.6rem;
}

.divider {
  border: none;
  border-top: 1px solid $color-neutral-04;
  margin: 3.2rem 0;
}
