@import '~theme/variables';

.overlay {
  background-color: $color-neutral-01;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $z-index-dialog-overlay;
}

.content {
  height: 100vh;
  overflow: auto;

  @include breakpoint(md) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.closeButton {
  position: fixed;
  top: $space-s;
  right: $space-s;

  @include breakpoint(md) {
    top: $space-m;
    right: $space-m;
  }
}
