@import '~theme/variables';

.header {
  padding-top: $space-s;
  padding-bottom: $space-m;

  @include breakpoint('sm') {
    padding: $space-xxl 0;
  }
}

.row {
  @include breakpoint('sm') {
    align-items: center;
  }
}

.imageContainer {
  position: relative;
  aspect-ratio: 1/1;
  border-radius: $border-radius-03;
  overflow: hidden;

  @include breakpoint('sm') {
    margin-right: 1.6rem;
  }

  img {
    object-fit: cover;
  }
}

.title {
  margin-bottom: 2.4rem;
  margin-top: 0.8rem;

  @include breakpoint('sm') {
    margin-top: 0;
  }
}

.name {
  color: $color-primary;
}

.description {
  margin-bottom: 2.4rem;

  @include breakpoint('sm') {
    margin-bottom: 3.2rem;
  }
}

.mobileAppContainer {
  position: relative;
  display: flex;
  gap: 3.2rem;
  align-items: flex-end;
}

.qrCode {
  display: none;

  @include breakpoint('sm') {
    display: block;
  }
}

.columnRight {
  display: flex;
  flex-direction: column;

  @include breakpoint('sm') {
    gap: 1.6rem;
  }
}

.qrCodeNote {
  display: none;

  @include breakpoint('sm') {
    display: block;
  }
}

.storeLinkContainer {
  display: flex;
}
