@import '~theme/variables';

.container {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  bottom: 0.8rem;
  left: 1.6rem;
  right: 1.6rem;
  overflow: hidden;
  box-shadow: $box-shadow-01;
}

.wrapper {
  background-color: $color-neutral-06;
  display: flex;
  flex-direction: row;
  border-radius: $border-radius-02;
}

.imageLink {
  display: flex;
  img {
    border-bottom-left-radius: $border-radius-02;
    border-top-left-radius: $border-radius-02;
    object-fit: cover;
  }
}

.content {
  position: relative;
  padding: 1.4rem 1.6rem 1.6rem 1.6rem;
}

.title {
  display: inline-block;
  padding-right: 4rem;

  &:hover {
    a {
      text-decoration: underline;
      text-decoration-color: $color-neutral-01;
    }
  }
}

.geoDistance {
  position: absolute;
  top: 1.4rem;
  right: 1.6rem;
  color: $color-neutral-02;
}

.cta {
  position: relative;
  margin-right: 1.6rem;
  display: inline-block;
}

.footerContent {
  margin-top: 0.8rem;
  border-radius: 0.8rem;
  display: inline-block;
}

.noBorder {
  border: 0;
  border-radius: 0;
}

.distanceContainer {
  display: flex;
  margin-bottom: 0.8rem;
}

.marker {
  fill: $color-neutral-02;
  font-size: 1.6rem;
  margin-right: 0.4rem;
}

.distanceDescription {
  margin-top: 0.1rem;
}

.buttonsContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 0.8rem;
}

.price {
  margin-left: 0.4rem;
}

.subscript {
  position: relative;
  display: inline-block;
  top: -0.8rem;
  left: -0.3rem;
}

.address {
  line-height: 2.3rem;
}

.button {
  padding: 0.9rem;
  width: 100%;
  justify-content: center;
  display: inline-flex;
  border-radius: $border-radius-02;
  transition: 0.2s ease-in-out;
  font-size: 1.3rem;
  font-weight: 700;
  white-space: nowrap;
  color: $color-neutral-01;
  background-color: $color-neutral-06;
  border: 0.1rem solid $color-neutral-01;

  &:active {
    background-color: $color-neutral-01;
    color: $color-neutral-06;
    border-color: $color-neutral-01;
  }

  &.primary {
    margin-left: $space-xs;
    color: $color-neutral-01;
    background-color: $color-primary;
    border: 0.1rem solid $color-primary;

    &:active {
      background-color: $color-neutral-01;
      color: $color-neutral-06;
      border-color: $color-neutral-01;
    }
  }
}

.closeButton {
  width: 4rem;
  height: 4rem;
  cursor: pointer;
  background-color: $color-neutral-06;
  border-radius: 5rem;
  display: flex;
  align-self: flex-end;
  justify-content: center;
  align-items: center;
  margin-bottom: $space-xs;
  margin-right: $space-xs;

  svg {
    font-size: 1.6rem;
  }
}
