@import '~theme/variables';

.description {
  p {
    margin-bottom: 2.4rem;
    color: $color-neutral-01;

    @include breakpoint(md) {
      font-size: $large-font-size;
      line-height: 28.8px;
    }
  }

  li p {
    margin-bottom: 0;
  }
}

.imageCol {
  display: flex;
  align-items: center;
  justify-content: stretch;

  & picture {
    width: 100%;
  }
}

.image {
  object-fit: cover;

  width: 100%;

  &.rounded {
    border-radius: $border-radius-04;
  }
}

.content {
  margin: auto 0;

  &.isLeft {
    margin-left: auto;
  }

  &.isRight {
    margin-right: auto;
  }
}

.buttonsWrapper {
  display: flex;
  flex-direction: column;
  gap: $space-s;
  margin-top: $space-l;
  margin-bottom: $space-s;
  flex-wrap: wrap;

  @include breakpoint(lg) {
    flex-direction: row;
  }

  @include breakpoint(sm) {
    gap: $space-m;
  }
}

.isCentered {
  margin: 0 auto;
}

.row {
  @include breakpoint(sm, max) {
    gap: $space-m;
  }
}
