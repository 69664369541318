@import '~theme/variables';

.address {
  display: flex;
  align-items: center;
  gap: 0.4 rem;
  font-style: normal;
  color: $color-neutral-02;

  svg {
    fill: currentColor;
    width: 1.6rem;
    height: 1.6rem;
  }
}
